import React, { FC, memo, ReactElement, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import { MODAL } from "../../../constants/path-constants";
import { useTweetImageStyles } from "./TweetImageStyles";
import { TaggedUserResponse } from "../../../types/user";
import ImageDescription from "../../ImageDescription/ImageDescription";
import TaggedImageUsers from "../../TaggedImageUsers/TaggedImageUsers";
import { VIDEO_EXTENSION } from "../../../constants/common-constants";
import VisibilitySensor from "react-visibility-sensor";

interface TweetImageProps {
    tweetId?: string;
    images?: any[];
    imageDescription?: string;
    taggedImageUsers?: TaggedUserResponse[];
}

const RenderVideo = ({ image, isModal, getExtension }: any) => {
    const [isAutoplayVideo, setIsAutoplayVideo] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const ref = useRef<any>();

    // Autoplay video on focus
    const onVideoAutoplay = (isVisible: boolean) => {
        setIsAutoplayVideo(isVisible);
        try {
            const isPlaying =
                ref.current.currentTime > 0 &&
                !ref.current.paused &&
                !ref.current.ended &&
                ref.current.readyState > ref.current.HAVE_CURRENT_DATA;
            if (isVisible && !isPlaying) {
                ref.current?.play();
            } else {
                ref.current?.pause();
            }
        } catch (error) {
            console.log("ERROR MESSAGE", error?.message);
        }
    };

    return (
        <VisibilitySensor key={image} onChange={onVideoAutoplay} scrollDelay={500} intervalCheck partialVisibility>
            <div style={{ position: 'relative', height: "100%" }}>
                {loading && <div className="skeletoLoader">Loading...</div>}
                <video
                    ref={ref}
                    controls
                    className={isModal ? "small" : ""}
                    autoPlay={isAutoplayVideo}
                    controlsList="nodownload"
                    onLoadedMetadata={() => setLoading(false)}
                    muted
                >
                    <source src={image?.src} type={`video/${getExtension(image)}`} />
                    Your browser does not support the video tag.
                </video>
            </div>
        </VisibilitySensor>
    );
};

const TweetImage: FC<TweetImageProps> = memo(
    ({ tweetId, images, imageDescription, taggedImageUsers }): ReactElement => {
        const classes = useTweetImageStyles();
        const history = useHistory();
        const location = useLocation();
        const isModal = location.pathname.includes(MODAL);

        // useVideoAutoplay(containerRef);
        const handleNavigation = (imageIndex: number) => {
            history.push({
                pathname: `${MODAL}/${tweetId}`,
                state: { background: location, imageIndex }
            });
        };

        const getClassName = () => {
            switch (images?.length) {
                case 1:
                    return classes["grid-layout-single"];
                case 2:
                    return classes["grid-layout-double"];
                case 3:
                    return classes["grid-layout-triple"];
                case 4:
                    return classes["grid-layout-quad"];
                default:
                    return classes["grid-layout-five"];
            }
        };

        const getExtension = (image: any) => {
            return image?.src?.split(".")?.pop();
        };

        return (
            <div id={"tweetImage"} className={classNames(classes.image, getClassName())}>
                {images?.map((image, index) => (
                    <>
                        {index > 3 ? (
                            <> </>
                        ) : (
                            <div
                                key={index}
                                className={`posts image${index + 1}`}
                                onClick={() => handleNavigation(index)}
                                style={{ cursor: "pointer" }} // Add pointer cursor to indicate it's clickable
                            >
                                {VIDEO_EXTENSION.includes(getExtension(image)) ? (
                                    <RenderVideo isModal={isModal} image={image} getExtension={getExtension} />
                                ) : (
                                    <>
                                        <div
                                            className="skeletoLoader"
                                            style={{ display: image.loading ? "block" : "none" }}
                                        >
                                            Loading...
                                        </div>
                                        <img
                                            className={isModal ? "small" : ""}
                                            src={image.src}
                                            alt={image.src}
                                            onLoad={(e) => (e.currentTarget.style.display = "block")}
                                            onError={(e) => (e.currentTarget.style.display = "none")}
                                        />
                                    </>
                                )}
                                {index > 2 && images?.length > 4 && (
                                    <div className="imageCountWrapper">
                                        <div className="imageCount">+{(images?.length || 0) - 4}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                ))}

                {imageDescription && <ImageDescription imageDescription={imageDescription} />}
                {taggedImageUsers && taggedImageUsers.length !== 0 && (
                    <TaggedImageUsers tweetId={tweetId!} taggedImageUsers={taggedImageUsers} />
                )}
            </div>
        );
    }
);

export default TweetImage;
