import produce, { Draft } from "immer";

import { UserState } from "./contracts/state";
import { UserActions, UserActionsType } from "./contracts/actionTypes";
import { LoadingStatus, PHOTO_TAGGING } from "../../../types/common";

export const initialUserState: UserState = {
    data: undefined,
    status: LoadingStatus.NEVER,
    errorMsg: undefined,
    tokenStatus: LoadingStatus.LOADING,
    accountDelete: false,
    blockedUsers: [],
};

export const userReducer = produce((draft: Draft<UserState>, action: UserActions) => {

    switch (action.type) {
        case UserActionsType.SET_USER_DATA:
            draft.data = action.payload;
            draft.accountDelete = false;
            draft.errorMsg = undefined;
            if (draft.data) { // Ensure draft.data is not undefined
                draft.data.mentionsCount = 0; // Set mentionsCount to 0 explicitly
            }
            draft.status = LoadingStatus.LOADED;
            break;
        case UserActionsType.SET_BLOCKED_USER_DATA:
            draft.blockedUsers = action.payload;
            break;
        case UserActionsType.SIGN_OUT:
            draft.errorMsg = undefined;
            draft.data = undefined;
            draft.status = LoadingStatus.LOADED;
            break;

        case UserActionsType.SET_UNREAD_MESSAGE:
            if (draft.data) {
                draft.data.unreadMessagesCount = draft.data.unreadMessagesCount + 1;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_USERNAME:
            if (draft.data) {
                draft.data.username = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_EMAIL:
            if (draft.data) {
                draft.data.email = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_PHONE:
            if (draft.data) {
                draft.data.phoneCode = action.payload.phoneCode;
                draft.data.phoneNumber = action.payload.phoneNumber;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_COUNTRY:
            if (draft.data) {
                draft.data.country = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_GENDER:
            if (draft.data) {
                draft.data.gender = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_LANGUAGE:
            if (draft.data) {
                draft.data.language = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_DIRECT:
            if (draft.data) {
                draft.data.isMutedDirectMessages = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_PRIVATE_PROFILE:
            if (draft.data) {
                draft.data.isPrivateProfile = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_COLOR_SCHEME:
            if (draft.data) {
                draft.data.colorScheme = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_BACKGROUND_COLOR:
            if (draft.data) {
                draft.data.backgroundColor = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_NEW_NOTIFICATION:
            if (draft.data) {
                draft.data.notificationsCount = draft.data.notificationsCount + 1;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.RESET_NOTIFICATIONS:
            if (draft.data) {
                draft.data.notificationsCount = 0;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_NEW_MENTION:
            if (draft.data) {
                draft.data.mentionsCount = draft.data.mentionsCount + 1;
                draft.status = LoadingStatus.LOADED;
                // draft.data.notificationsCount = draft.data.notificationsCount + 1;
                // draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.RESET_MENTIONS:
            if (draft.data) {
                draft.data.mentionsCount = 0;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_FOLLOWERS_SIZE:
            if (draft.data) {
                draft.data.followersCount = draft.data.followersCount + 1;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_PROFILE_STARTED:
            if (draft.data) {
                draft.data.profileStarted = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_PIN_TWEET_ID:
            if (draft.data) {
                draft.data.pinnedTweetId = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_READ_MESSAGE:
            if (draft.data) {
                draft.data.unreadMessagesCount = action.payload;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_USER_FOLLOWING:
            if (draft.data) {
                draft.data.followersCount = action.payload ? draft.data.followersCount + 1 : draft.data.followersCount - 1;
                draft.status = LoadingStatus.LOADED;
            }
            break;

        case UserActionsType.SET_USER_LOADING_STATE:
            draft.status = action.payload;
            break;
        case UserActionsType.SET_VERACITY_SCORE:
            if (draft.data) {
                draft.data.veracityScore = action.payload;
            }
            break;
        case UserActionsType.SET_TOKEN_LOADING_STATE:
            draft.tokenStatus = action.payload;
            break;
        case UserActionsType.SET_PHOTO_TAGGING:
            if (draft.data) {
              draft.data = {
                ...draft.data,
                photoTaggingEnabled: action.payload.isPhotoTaggingEnabled ?? false,
                whoCanTagInPhoto: action.payload.whoCanTagInPhoto ?? PHOTO_TAGGING.EVERYONE,
              };
            }
            break;
        case UserActionsType.SET_DELETE_ACCOUNT:
            draft.accountDelete = action.payload;
            break;
        case UserActionsType.SET_ERROR_MESSAGE:
            draft.errorMsg = action.payload;
            break;
        default:
            break;
    }
}, initialUserState);
